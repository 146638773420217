.ProjectsPage {
  width: 90%;
  max-width: 720px;
  margin: 0 auto;
  text-align: left;
  line-height: 1.5rem;
  border-top: 2px solid #f1f1f1;
}

.ProjectsPage h1 {
  text-align: center;
}