body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: rgb(44, 44, 44);
  color: white;
}

.svg-404 {
  width: 1000px;
  height: 480px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -400px;
  margin-top: -240px;
}

.link {
  color: #a9a9fd;
}