.ProjectCard {
  padding-top: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-shadow: rgba(0, 0, 0, 0.156863) 0px 3px 10px, rgba(0, 0, 0, 0.227451) 0px 3px 10px;
  transition: box-shadow 0.5s ease;
  text-align: center;
  position: relative;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 720px;
}

.ProjectCard:hover {
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 19px 60px, rgba(0, 0, 0, 0.219608) 0px 15px 20px;
}

.ProjectCard h4 {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 0px;
  color: #f1f1f1;
}

.ProjectCard p {
  margin-top: 5px;
  font-weight: 200;
  font-size: 1rem;
  color: #f1f1f1;
  margin-bottom: 0px;
}