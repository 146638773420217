.NotFound {
  width: 70%;
  max-width: 720px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.5rem;
  border-top: 2px solid #f1f1f1;
}

.NotFound h1 {
  font-weight: 400;
}