.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  min-height: 150px;
  padding: 20px;
  color: #f1f1f1;
}

.App-header h2 {
  font-weight: 200;
}

.App-intro {
  font-size: large;
  color: #f1f1f1;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.NavBar {
  text-align: center;
  padding: 10px;
}

.NavItem {
  margin-left: 15px;
  margin-right: 15px;
}

.NavBar a {
  text-decoration: none;
  outline: none;
  color: #a9a9fd;
}
.ProjectCard {
  padding-top: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-shadow: rgba(0, 0, 0, 0.156863) 0px 3px 10px, rgba(0, 0, 0, 0.227451) 0px 3px 10px;
  transition: box-shadow 0.5s ease;
  text-align: center;
  position: relative;
  border-radius: 3px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  max-width: 720px;
}

.ProjectCard:hover {
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 19px 60px, rgba(0, 0, 0, 0.219608) 0px 15px 20px;
}

.ProjectCard h4 {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 0px;
  color: #f1f1f1;
}

.ProjectCard p {
  margin-top: 5px;
  font-weight: 200;
  font-size: 1rem;
  color: #f1f1f1;
  margin-bottom: 0px;
}
.ProjectsPage {
  width: 90%;
  max-width: 720px;
  margin: 0 auto;
  text-align: left;
  line-height: 1.5rem;
  border-top: 2px solid #f1f1f1;
}

.ProjectsPage h1 {
  text-align: center;
}
.IntroPage {
  width: 70%;
  max-width: 720px;
  margin: 0 auto;
  text-align: left;
  font-weight: 200;
  border-top: 2px solid #f1f1f1;
}
.AboutPage {
  width: 70%;
  max-width: 720px;
  margin: 0 auto;
  text-align: left;
  line-height: 1.5rem;
  border-top: 2px solid #f1f1f1;
}

.AboutPage h1 {
  text-align: center;
}

.icon svg {
  color: white;
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  fill: silver;
  margin-right: 10px;
}


.RandomPage {
  width: 70%;
  max-width: 720px;
  margin: 0 auto;
  text-align: left;
  font-weight: 200;
  border-top: 2px solid #f1f1f1;
  padding-top: 2rem;
}

.RandomPage .quote {
  font-family: Copperplate, serif;
  position: relative;
  quotes: "\201C""\201D""\2018""\2019";
  color:#afb3b9;
  font-style:oblique;
  font-size: 18px;
  padding-top: 20px;
  padding-left: 55px;
  padding-right: 20px;
  border-left: 4px solid #80848b;
  background-color:#3e3e3e;
}

.RandomPage .quote:before {
  font-family: Georgia, serif;
  color: #ccc;
  content:open-quote;
  font-size: 5em;
  line-height: 0em;
  position: absolute;
  left: 3px;
  top: 42px;
}

.RandomPage .author {
  font-family: Copperplate, serif;
  display:block;
  color: #afb3b9;
  font-size: 12px;
  padding-top:5px;
  padding-left: 60px;
  padding-bottom: 10px;
  border-left: 4px solid #80848b;
  background-color:#3e3e3e;

}

.RandomPage .author:before {
  content: "\2014   \2009";
}

.RandomPage .refresh {
  width:24px;
  height:24px;
  vertical-align: middle;
}

.RandomPage .refresh:hover {
  cursor: pointer;
}

.RandomPage .refreshContainer {
  margin-left: 0.5rem;
}
.NotFound {
  width: 70%;
  max-width: 720px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.5rem;
  border-top: 2px solid #f1f1f1;
}

.NotFound h1 {
  font-weight: 400;
}
.LinksPage {
  width: 70%;
  max-width: 720px;
  margin: 0 auto;
  text-align: left;
  font-weight: 200;
  border-top: 2px solid #f1f1f1;
  padding-top: 2rem;
}


.LinksPage a {
  color: #a9a9fd;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: rgb(44, 44, 44);
  color: white;
}

.svg-404 {
  width: 1000px;
  height: 480px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -400px;
  margin-top: -240px;
}

.link {
  color: #a9a9fd;
}
